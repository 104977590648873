<template>
    <div class="view admins">
        <h1>This is the admins page</h1>
    </div>
</template>

<script>
    export default {
        name: "Admins"
    }
</script>

<style lang="scss" scoped>
    .admins {

    }
</style>
